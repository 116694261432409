import React from 'react'

const DocumentPreviewModal = ({url, type}) => {

  if (type === 'pdf') {
    return (
      <div className="d-flex justify-content-center h-100 w-auto align-items-center">
        <iframe src={url} width="100%" height="100%" title="PDF Preview"></iframe>
      </div>
    )
  }

  return (
    <div className="d-flex justify-content-center align-items-center max-vw-100 h-auto">
      <img src={url} alt='document' className="vw-100 h-auto"/>
    </div>
  )
}

export default DocumentPreviewModal
