import React, {createContext, useContext, useEffect, useRef, useState} from 'react'
import _ from 'lodash'
import {selectListData} from "@metronic/api/general.js"
import {SecurityType} from '@metronic/Enums/Securities/SecurityType.js'

const SecuritiesContext = createContext({})

export const useSecuritiesContext = () => {
  return useContext(SecuritiesContext)
}

export const recordSecuritiesApiData = (securitiesList, setSecuritiesList, assetsList, securitiesApiData, setIsSecuritiesListReady) => {
  if (!_.isEmpty(securitiesList) && !_.isEmpty(securitiesApiData)) {

    const newList = securitiesList.map(item => {

      let fields = item?.fields
      let ticker = fields?.ticker?.value

      if (!ticker || item?.type === SecurityType.BOND) {
        return item
      }

      if (ticker && item?.type === SecurityType.STOCK) {

        if (_.isEmpty(securitiesApiData?.stock_data)) {
          return item
        }

        let stockData = securitiesApiData?.stock_data
        let stockOverviewData = stockData?.stock_overview_data?.find(i => i.stock_ticker === ticker) || []
        let stockPrice = stockData?.stock_price?.find(i => i.stock_ticker === ticker) || []
        let stocksData = stockData?.stock_data?.filter(i => i.stock_ticker === ticker) || []

        if (!fields?.current_price?.value && ticker && stockPrice?.highest_data?.c) {
          item.fields['current_price']['value'] = stockPrice?.highest_data?.c
        }

        if (!fields?.payout_asset?.value && stockOverviewData?.data) {
          const asset = assetsList.find(i => i.label === stockOverviewData?.data['Currency'])

          if (!_.isEmpty(asset)) {
            item.fields['payout_asset']['value'] = asset.value
            item.fields['payout_asset']['payout_asset_code'] = asset.label
          }
        }

        item['api_data'] = {
          stock_data: !_.isEmpty(stocksData) ? [...stocksData] : [],
          stock_price: !_.isEmpty(stockPrice) ? stockPrice : [],
          stock_overview_data: !_.isEmpty(stockOverviewData) ? stockOverviewData : []
        }

        return item

      } else if (ticker && item?.type === SecurityType.INDEX) {

        if (_.isEmpty(securitiesApiData?.index_data)) {
          return item
        }

        let indexData = securitiesApiData?.index_data
        let indexPrice = indexData?.index_price?.find(i => i.index_ticker === ticker) || []
        let indicesData = indexData?.index_data?.filter(i => i.index_ticker === ticker) || []

        if (!fields?.current_price?.value && ticker && indexPrice?.highest_data?.c) {
          item.fields['current_price']['value'] = indexPrice?.highest_data?.c
        }

        item['api_data'] = {
          index_data: !_.isEmpty(indicesData) ? indicesData : [],
          index_price: !_.isEmpty(indexPrice) ? indexPrice : [],
        }

        return item
      }

      return item
    })

    if (setIsSecuritiesListReady) {
      setIsSecuritiesListReady(true)
    }

    setSecuritiesList(newList)
  }
}

export const SecuritiesProvider = ({children, page_data, customer_securities, ...props}) => {
  const [activeTab, setActiveTab] = useState('')
  const [securitiesList, setSecuritiesList] = useState([])
  const [activeSecurityId, setActiveSecurityId] = useState(0)
  const [activeSecurity, setActiveSecurity] = useState({})
  const [tabList, setTabList] = useState([])
  const [allListTab, setAllListTab] = useState('')
  const [ownListTab, setOwnListTab] = useState('')
  const [tabData, setTabData] = useState([])
  const [purchaseList, setPurchaseList] = useState([])
  const tableRef = useRef(null)
  const [ownSelectedSecurities, setOwnSelectedSecurities] = useState({})
  const [isSecuritiesListReady, setIsSecuritiesListReady] = useState(false)
  const [assetsList, setAssetsList] = useState([])
  const [securitiesApiData, setSecuritiesApiData] = useState([])

  const [data, setData] = useState([])

  useEffect(() => {
    selectListData('assets_list').then(r => {
      setAssetsList(r)
    })
  }, [])

  useEffect(() => {
    if (page_data) {
      setData(page_data)

      if (page_data?.securities?.length) {
        setSecuritiesList(page_data.securities)
      }

    }
  }, [page_data])

  useEffect(() => {
    setSecuritiesApiData(props?.securities_data)
  }, [props])

  useEffect(() => {
    if (assetsList?.length && !_.isEmpty(securitiesApiData)) {
      recordSecuritiesApiData(securitiesList, setSecuritiesList, assetsList, securitiesApiData)
    }
  }, [assetsList, securitiesApiData])

  useEffect(() => {
    if (customer_securities && customer_securities.length) {
      setPurchaseList(customer_securities)
    }
  }, [customer_securities])

  useEffect(() => {
    if (data && data.tabs) {
      setTabList(data.tabs.map((item) => {
        return ({
          id: item.id,
          title: item.title
        })
      }))

      const allListBlock = data.tabs.find(tab =>
        tab.blocks?.find(block => block.name === 'securities_table' && block.is_user_list === null))?.id

      const ownListBlock = data.tabs.find(tab =>
        tab.blocks?.find(block => block.name === 'securities_table' && block.is_user_list))?.id

      setAllListTab(allListBlock || '')
      setOwnListTab(ownListBlock || '')
    }
  }, [data])

  useEffect(() => {
    if (tabList && tabList.length && !activeTab) {
      setActiveTab(String(tabList[0]?.id))
    }
  }, [tabList])

  useEffect(() => {
    if (data && activeTab && activeTab.length) {
      setTabData(data.tabs.find(tab => tab.id === activeTab)?.blocks)
    }
  }, [data, activeTab])

  useEffect(() => {
    if (ownSelectedSecurities?.name && (activeTab !== ownListTab)) {
      setOwnSelectedSecurities({})
    }
  }, [activeTab, ownSelectedSecurities])

  return (
    <SecuritiesContext.Provider
      value={{
        ...props,
        activeTab, setActiveTab,
        securitiesList, setSecuritiesList,
        activeSecurityId, setActiveSecurityId,
        activeSecurity, setActiveSecurity,
        tabList, setTabList,
        ownSelectedSecurities, setOwnSelectedSecurities,
        data,
        allListTab,
        ownListTab,
        tabData,
        tableRef,
        purchaseList,
        isSecuritiesListReady,
        securitiesApiData,
        assetsList
      }}>
      {children}
    </SecuritiesContext.Provider>
  )
}
