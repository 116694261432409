import React, {useContext} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap"
import {Card4} from "@metronic/_metronic/partials/content/cards/Card4"
import clsx from "clsx"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import AddDocumentModal from "@metronic/Components/Modal/AddDocumentModal.jsx"
import {usePage} from "@inertiajs/react"
import {useTranslation} from "react-i18next"
import DocumentPreviewModal from "@metronic/Components/Modal/DocumentPreviewModal.jsx"

const svgMapper = {
  pdf: 'media/svg/files/pdf.svg',
  jpg: 'media/svg/files/jpg-icon.png',
  jpeg: 'media/svg/files/jpg-icon.png',
  png: 'media/svg/files/png-icon.png',
  heic: 'media/svg/files/heic.png',
}

const DocumentsTab = () => {
  const {openModal} = useContext(ModalContext)
  const { t } = useTranslation()
  const {props: pageProps} = usePage()
  const documents = pageProps.documents

  const uploadDocumentHandler = () => {
    openModal({
      title: t('Upload document'),
      content: <AddDocumentModal />,
    })
  }

  const openDocumentHandler = (url, name, type) => {
    openModal({
      title: name,
      content: <DocumentPreviewModal url={url} type={type} />,
      fullscreen: true
    })
  }

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <div className="fw-bold text-gray-900 fs-3">
            Documents
          </div>
          <div>
            <Button
              type="button"
              className="btn btn-sm btn-primary fw-bold"
              color="primary"
              onClick={uploadDocumentHandler}
            >Add new document</Button>
          </div>
        </CardHeader>

        <CardBody>
          <Row className='g-6 g-xl-9'>
            {(documents && documents.length) ? documents.map((doc, idx) => {
              const docType = doc.meta.file_name.split('.').pop()
              return (
              <Col key={idx} className={clsx(
                VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3' :
                  'col-12 col-sm-6 col-md-4 col-lg-3'
              )}
                onClick={() => openDocumentHandler(doc.img_url, doc.meta.file_name, docType)}
              >
                <Card4
                  icon={svgMapper[docType]}
                  title={doc?.document_type?.type}
                  createdDate={doc.created_at}
                  status={doc.status}
                  name={doc.meta.file_name}
                />
              </Col>
              )}) :
              <span className="text-center w-100 fw-bold fs-3 py-10">No data</span>
            }
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default DocumentsTab
