import React, {useEffect, useRef, useState} from 'react'
import classnames from "classnames"
import {
  Row,
  Col,
  Form,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Label,
  Input,
  Button,
  Card,
  CardBody
} from "reactstrap"
import Select from "react-select"
// import PhoneInputComponent from "@metronic/Components/IntlTelInput/intlTelInput"
// import {selectListData} from "@metronic/api/general"
import {useFormik} from "formik"
import * as Yup from "yup"
import {toTwoPrecision, validateFloatValue} from "@metronic/helpers/utils"
import {useTranslation} from "react-i18next"
import {router, usePage} from '@inertiajs/react'
import {selectListData} from "@metronic/api/general.js";
import {toast} from "react-toastify";
import {capitalize} from "lodash";

const BankTransferForm = () => {
  const minimumWithdrawalAmount = useRef(import.meta.env?.VITE_MINIMUM_AMOUNT_WITHDRAWAL_BANK ?? 50)
  // const dispatch = useDispatch()
  // const {accountBalance, plan} = useSelector(s => ({
  //   accountBalance: s.Profile.accountBalance,
  //   plan: s.Profile.user.profile?.plan
  // }))
  const {props: pageProps} = usePage()
  const [lockedAssetBalance, setLockedAssetBalance] = useState(0)
  const [lockedAssetUsdBalance, setLockedAssetUsdBalance] = useState(0)
  const [useUnlockedBalance, setUseUnlockedBalance] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [activeTabFee, setActiveTabFee] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState('')
  const [amount, setAmount] = useState('')
  const [fees, setFees] = useState([])
  const [assets, setAssets] = useState([])
  const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(0.00)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [countriesOptions, setCountriesOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [customerId, setCustomerId] = useState('')
  const isViteMaxSum = import.meta.env?.VITE_MAX_SUM_ONLY ?? ''
  const {t} = useTranslation()
  const userData = pageProps.auth.user
  const formik = useFormik({
    initialValues: {
      withdraw_amount: '',
      asset_id: '',
      country: '',
      canton: '',
      address: '',
      postal_code: '',
      city: '',
      account_holder: '',
      account_number: '',
      city_to_withdraw: '',
      swift_code: '',
      iban: '',
      bank_to_withdraw: '',
      phone: '',
    },
    validationSchema: Yup.object().shape({
      withdraw_amount: Yup.number().required().min(minimumWithdrawalAmount.current),
      asset_id: Yup.string().required(),
      country: Yup.string().required(),
      canton: Yup.string().required(),
      address: Yup.string().required(),
      postal_code: Yup.string().required(),
      city: Yup.string().required(),
      account_holder: Yup.string().required(),
      account_number: Yup.string().required(),
      city_to_withdraw: Yup.string().required(),
      swift_code: Yup.string().required(),
      iban: Yup.string().required(),
      phone: Yup.string().required(),
      bank_to_withdraw: Yup.string().required(),
    }),
    onSubmit: (data) => {
      setIsButtonDisabled(true)

      // if (isReal) {
      //   data = {...data, use_unlocked_balance: true}
      // }

      data = {
        ...data,
        customer_id: customerId,
        fee_value: activeTabFee,
      }

      router.post('/withdrawal/bank-transfer', data, {
        onSuccess() {
          router.reload({only: ['errors', 'flash']})
          resetForm()
        }
      })

      // toggleTab(4)
      setIsButtonDisabled(false)
    }
  })

  const resetForm = () => {
    setAmount('')
    setSelectedAsset('')
    formik.setFieldValue('withdraw_amount', '')
    formik.setFieldValue('asset_id', '')
    formik.setFieldValue('bank_to_withdraw', '')
    formik.setFieldValue('city_to_withdraw', '')
    formik.setFieldValue('swift_code', '')
    formik.setFieldValue('iban', '')
    formik.setFieldValue('account_number', '')
  }

  const getCountriesData = () => {
    selectListData('countries').then(r => {
      setCountriesOptions(r)
    })
    setCountriesOptions([])
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab === 2) {
      if (!formik.values.asset_id) {
        toast.error(t('Please select currency'))
      } else if (!formik.values.withdraw_amount) {
        toast.error(t('Please enter an amount'))
      }
      else if (formik.values.withdraw_amount < Number(minimumWithdrawalAmount.current)) {
        toast.error(t('Minimum withdrawal amount is $') + minimumWithdrawalAmount.current)
      }
      else if (formik.values.asset_id && formik.values.withdraw_amount) {
        setActiveTab(tab)
      }
      } else if (tab === 3) {
      if (formik.values.asset_id && formik.values.withdraw_amount && formik.values.country &&
        formik.values.canton && formik.values.address &&
        formik.values.postal_code && formik.values.city && formik.values.phone) {
        setActiveTab(tab)
      } else if (!formik.values.asset_id) {
        toast.error(t('Please select currency'))
      } else if (!formik.values.withdraw_amount) {
        toast.error(t('Please enter an amount'))
      } else if (!formik.values.country) {
        toast.error(t('Please select a country'))
      } else if (!formik.values.canton) {
        toast.error(t('Please select a canton'))
      } else if (!formik.values.address) {
        toast.error(t('Please select an address'))
      } else if (!formik.values.postal_code) {
        toast.error(t('Please select a postal code'))
      } else if (!formik.values.city) {
        toast.error(t('Please select a city'))
      } else if (!formik.values.phone) {
        toast.error(t('Please select a phone'))
      }
      } else {
      setActiveTab(tab)
      }

    }
  }

  function toggleTabFee(tab) {
    if (activeTabFee !== tab) {
      setActiveTabFee(tab)
    }
  }

  const changeAmountHandler = e => {
    const newAmount = e.target.value
    setAmount(newAmount)

    if (selectedAsset) {
      setTotalWithdrawAmount(calcAmount(newAmount))
    }
  }

  const calcFee = (activeTabFee) => {
    if (activeTabFee) {
      return (fees[activeTabFee].fee / 100) + 1
    }

    return 1
  }

  const calcAmount = (amount) => {
    return Number(amount * calcFee(activeTabFee)).toFixed(2) * 1
  }

  const calculateMaxSumHandler = e => {
    let formEl = e.target.closest('form')

    let data
    let formData = Object.fromEntries(new FormData(formEl))

    // if (isReal) {
    //   data = {...formData, use_unlocked_balance: true}
    // } else {
    //   data = formData
    // }

    data = formData
    formData.type = 'fiat'

    if (selectedAsset) {
      setAmount(toTwoPrecision(lockedAssetBalance))
      setTotalWithdrawAmount(calcAmount(toTwoPrecision(lockedAssetBalance)))
    } else {
      toast.error('Please select currency')
    }

    // calcAmount(toTwoPrecision(lockedAssetBalance))

    // calculateMaxSum(data).then(r => {
    //   if (r.asset_amount > 0) {
    //     if (r.success) {
    //       setAmount(Number(r.asset_amount).toFixed(2))
    //       setTotalWithdrawAmount(Number(r.total_asset_amount).toFixed(2))
    //     } else {
    //       toast.error(r.message)
    //     }
    //   } else {
    //     toast.warning(r.message)
    //   }
    // })
  }

  useEffect(() => {
    calcAmount(toTwoPrecision(lockedAssetBalance))
  }, [amount])

  const submitHandler = (e) => {
    e.preventDefault()

    if (!formik.values.account_holder) {
      toast.error(t('Please enter account holder'))
    } else if (!formik.values.bank_to_withdraw) {
      toast.error(t('Please enter bank'))
    } else if (!formik.values.city_to_withdraw) {
      toast.error(t('Please enter city'))
    } else if (!formik.values.account_number) {
      toast.error(t('Please enter account number'))
    } else if (!formik.values.iban) {
      toast.error(t('Please enter iban'))
    } else if (!formik.values.swift_code) {
      toast.error(t('Please enter swift code'))
    } else {
      formik.handleSubmit(e)
    }
  }

  useEffect(() => {
    setTotalWithdrawAmount(calcAmount(amount))
  }, [activeTabFee, selectedAsset])

  useEffect(() => {
    formik.setFieldValue('withdraw_amount', amount)
  }, [amount])

  useEffect(() => {
    let balance = pageProps.account_balance.all.filter(i => ! i.is_real)
    let withdrawAsset = balance?.find(i => Number(i.asset_id) === Number(selectedAsset.value || ''))
    let assets = []
    balance.forEach(i => {
      if (i.type === 'fiat') {
        assets.push({value: i.asset_id, label: i.code, price: i.price})
      }
    })

    setAssets(assets)
    setLockedAssetBalance(Number(withdrawAsset?.balance || 0).toFixed(2) * 1)
    setLockedAssetUsdBalance(withdrawAsset?.balance * withdrawAsset?.price || 0)
  }, [pageProps, selectedAsset, useUnlockedBalance])

  useEffect(() => {
    if (userData?.profile?.plan) {
      selectListData('transaction_fiat_fees').then(r => {
        if (userData.profile.plan === "high bank fee") {
          r = {high: {...r.high, active: true}}
        }

        setFees(r)

        Object.entries(r).map(([key, value]) => {
          if (value.active) {
            setActiveTabFee(key)
          }
        })
      })
    }
  }, [userData])

  useEffect(() => {
    getCountriesData()
  }, [])

  useEffect(() => {
    if (userData.profile) {
        formik.setFieldValue('canton', !userData.profile.state ? '' : userData.profile.state)
        formik.setFieldValue('address', !userData.profile.address ? '' : userData.profile.address)
        formik.setFieldValue('postal_code', !userData.profile.postal_code ? '' : userData.profile.postal_code)
        formik.setFieldValue('city', !userData.profile.city ? '' : userData.profile.city)
        formik.setFieldValue('phone', !userData.profile.phone ? '' : userData.profile.phone)
        formik.setFieldValue('account_holder',
          !userData.name ? '' : userData.name
        )
        setCustomerId(userData.profile.user_id)
    }
  }, [userData])

  useEffect(() => {
    if (userData && countriesOptions?.length) {
      setSelectedCountry(countriesOptions.find(i => i.value === userData.profile.country))
      formik.setFieldValue('country', !userData.profile.country ? '' : userData.profile.country)
    }
  }, [userData, countriesOptions])

  return (
    <Form onSubmit={submitHandler}
    >
      <Card className="bg-body card-p-0">
        <CardBody className='mt-10'>
          <div className={`${activeTab === 4 ? 'd-none' : ''}`}>
            {/*<Col className="col-12 d-flex justify-content-between">*/}
            <h4 className="card-lable text-center mb-7">{t("Enter payment information")}</h4>
            {/*</Col>*/}
          </div>
          <div className="step-arrow-nav mx-10 mb-4">
            <Nav
              className={`nav-pills border-bottom custom-nav nav-justified pb-5 mb-5 ${activeTab === 4 ? 'd-none' : ''}`}
              role="tablist">
              <NavItem>
                <NavLink
                  href="#"
                  id="steparrow-gen-info-tab"
                  className={classnames('fs-5 fw-bold', {
                    'active': activeTab === 1,
                    'done text-muted': activeTab <= 5 && activeTab > 1,
                  })}
                  onClick={(e) => {
                    e.preventDefault()
                    toggleTab(1)
                  }}
                >
                  {t("Currency")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  id="steparrow-gen-info-tab"
                  className={classnames('fs-5 fw-bold', {
                    'active': activeTab === 2,
                    'done text-muted': activeTab <= 5 && activeTab > 2,
                  })}
                  onClick={(e) => {
                    e.preventDefault()
                    toggleTab(2)
                  }}
                >
                  {t("Contacts")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  id="steparrow-gen-info-tab"
                  className={classnames('fs-5 fw-bold text-nowrap', {
                    'active': activeTab === 3,
                    'done text-muted': activeTab <= 5 && activeTab > 3,
                  })}
                  onClick={(e) => {
                    e.preventDefault()
                    toggleTab(3)
                  }}
                >
                  {t("Billing info")}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent className='mx-10 mb-10' activeTab={activeTab}>
            <TabPane id="steparrow-gen-info" tabId={1} name="currency">
              <Row className="mb-3">
                <Col xs={12}>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="text-muted">Available</span>
                    <span className="fs-15">
                      <span
                        className="available_sum">{toTwoPrecision(lockedAssetBalance)} (${toTwoPrecision(lockedAssetUsdBalance)})</span>
                      <span className="available_code"> {selectedAsset.label}</span>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <div className="input-group mb-6 d-flex">
                        <Select
                          className="select-currency w-100"
                          placeholder={t("Select currency . . . ")}
                          name="asset_id"
                          value={selectedAsset}
                          onChange={(selectedOption) => {
                            setSelectedAsset(selectedOption)
                            formik.setFieldValue('asset_id', selectedOption.value)
                          }}
                          options={assets}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <Input
                          type="text"
                          name="withdraw_amount"
                          placeholder={`${t("Amount")} *`}
                          className="form-control"
                          autoComplete="off"
                          disabled={String(isViteMaxSum) === 'true'}
                          value={amount}
                          onChange={(e) => {
                            let dotInput = validateFloatValue(e.target.value)

                            if (dotInput !== false) {
                              changeAmountHandler(e)
                              setAmount(dotInput)
                              formik.setFieldValue('withdraw_amount', dotInput)
                            } else {
                              return false
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={12}>
                  <div>
                    <a
                      href='#'
                      className="btn btn-link btn-color-primary btn-active-color-primary"
                      onClick={calculateMaxSumHandler}
                    >{t("Max sum")}</a>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bold">{t("Fee")}</h3>
                  </div>
                  <div className="border-1">
                    <Nav
                      className="custom-nav nav-justified square border border-primary rounded-2"
                      role="tablist"
                    >
                      {fees &&
                        Object.keys(fees).map((fee) =>
                          <NavItem key={fee}>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: activeTabFee === fee,
                                "bg-primary text-inverse-primary rounded": activeTabFee === fee,
                              })}
                              onClick={() => {
                                toggleTabFee(fee)
                              }}
                            >
                              {t(`${capitalize(fee)}`)}
                            </NavLink>
                          </NavItem>
                        )}
                    </Nav>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12 mb-3 mt-1">
                  <TabContent activeTab={activeTabFee}>
                    {Object.keys(fees).map((fee) =>
                      <TabPane key={fee} id="steparrow-gen-info" tabId={fee}>
                        <div>
                          <Row>
                            <Col className="d-flex justify-content-between">
                              <span className="fs-14">
                                {t("Fee")}: {fees[fee].fee}% ({
                                toTwoPrecision((amount * selectedAsset.price) * (Number(fees[fee].fee) / 100))
                              }$)
                              </span>
                              <span className="fs-14">{t("Estimated time")}: {t(fees[fee].time)}</span>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                </Col>
                <Col className="mb-4 lh-lg border-top py-4">
                  <div className="pt-1">
                    <span className="fs-6 fw-bold text-gray-600">
                      {t("Withdrawal Amount")}:
                      <span className="float-end">
                        {selectedAsset ? toTwoPrecision(amount) : '0.00'} {selectedAsset.label}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="fs-6 fw-bold text-gray-600">
                      {t("Paying from Available Balance")}:
                      <span className="float-end">
                        {selectedAsset ? toTwoPrecision(totalWithdrawAmount - amount) : '0.00'} {selectedAsset.label}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="fs-6 fw-bold">{t("Total Amount")}:
                      <span className="float-end"> {selectedAsset ? toTwoPrecision(totalWithdrawAmount) : '0.00'} {selectedAsset.label}</span>
                    </span>
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <div className="col-6">
                  <Button
                    type="button"
                    className="tn btn-sm btn-light-success border border-dashed border-success w-100 fs-16 ms-auto"
                    onClick={() => {
                      toggleTab(activeTab + 1)
                    }}
                  >
                    {t("NEXT")} <i className="ki-outline ki-double-right fs-2 pb-1"></i>
                  </Button>
                </div>
              </div>
            </TabPane>

            <TabPane id="steparrow-description-info" tabId={2}>
              <div>
                <div className="col-sm-12 w-100 mb-3 ">
                  <Label htmlFor="inputCountry" className="form-label mb-0">
                    {t("Country")}
                    <sup className="text-danger fs-12">*</sup>
                  </Label>
                  <Select
                    placeholder={t("Country")}
                    id="inputCountry"
                    name="country"
                    options={countriesOptions}
                    value={selectedCountry}
                    onChange={(selected) => {
                      setSelectedCountry(selected)
                      formik.setFieldValue('country', selected.value)
                    }}
                    // required={true}
                  >
                    <option value="">{t("Select country")}</option>
                  </Select>
                </div>
                <div className="input-group mb-3">
                  <div className="col-sm-12 w-100">
                    <Label htmlFor="canton" className="form-label mb-0">
                      {t("Canton")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      value={formik.values.canton}
                      onChange={(e) => {
                        formik.setFieldValue("canton", e.target.value)
                      }}
                      id="canton"
                      placeholder={t("Canton")}
                      name="canton"
                    />
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="col-sm-12 w-100">
                    <Label htmlFor="address" className="form-label mb-0">
                      {t("Address")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="address"
                      autoComplete="off"
                      value={formik.values.address}
                      onChange={(e) => {
                        formik.setFieldValue("address", e.target.value)
                      }}
                      placeholder={t("Address")}
                      name="address"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-sm-6">
                    <Label htmlFor="postal_code" className="form-label mb-0">
                      {t("Postal/ZIP code")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder={t("Postal/ZIP code")}
                      value={formik.values.postal_code}
                      onChange={(e) => {
                        formik.setFieldValue("postal_code", e.target.value)
                      }}
                      name="postal_code"
                      // required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <Label htmlFor="city" className="form-label mb-0">
                      {t("City")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.setFieldValue("city", e.target.value)
                      }}
                      placeholder={t("City")}
                      name="city"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between my-3">
                  <span className="small text-muted">{t("Contact")}</span>
                </div>
                <div className="input-group mb-3">
                  <div className="col-12 col-sm-6 pe-0 pe-sm-3">
                    <Label htmlFor="phone" className="form-label mb-0">
                      {t("Phone")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      value={formik.values.phone}
                      onChange={(e) => {
                        formik.setFieldValue("phone", e.target.value)
                      }}
                      placeholder={t("Phone")}
                      name="full_phone"
                    />
                    {/*<div className="input-group col-6">*/}
                    {/*  <PhoneInputComponent*/}
                    {/*    phone={formik.values.phone}*/}
                    {/*    onChange={(newValue) =>*/}
                    {/*      formik.setFieldValue('phone', newValue)*/}
                    {/*    }*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                </div>

                <div className="d-flex align-items-start gap-5 mt-10">

                  <Button
                    type="button"
                    className="btn btn-sm btn-light-warning border border-dashed border-warning fs-6 w-50 btn-label"
                    onClick={() => {
                      toggleTab(activeTab - 1)
                    }}
                  >
                    <i className="ki-outline ki-double-left fs-2 pb-1"></i> {t("PREVIOUS")}
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-sm btn-light-success border border-dashed border-success fs-6 w-50 btn-label"
                    onClick={() => {
                      toggleTab(activeTab + 1)
                    }}
                  >
                    {t("NEXT")} <i className="ki-outline ki-double-right fs-2 pb-1"></i>
                  </Button>
                </div>
              </div>
            </TabPane>

            <TabPane id="steparrow-description-info" tabId={3}>
              <Row>
                <div className="input-group mb-3">
                  <Col>
                    <Label htmlFor="account_holder" className="form-label mb-0">
                      {t("Account holder")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("Account holder")}
                      name="account_holder"
                      value={formik.values.account_holder}
                      onChange={(e) => {
                        formik.setFieldValue("account_holder", e.target.value)
                      }}
                    />
                  </Col>
                </div>
                <div className="input-group mb-3">
                  <Col>
                    <Label htmlFor="bank_to_withdraw" className="form-label mb-0">
                      {t("Bank")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("Bank")}
                      name="bank_to_withdraw"
                      autoComplete={'off'}
                      value={formik.values.bank_to_withdraw}
                      onChange={(e) => {
                        formik.setFieldValue("bank_to_withdraw", e.target.value)
                      }}
                    />
                  </Col>
                </div>
                <div className="mb-3">
                  <Col>
                    <Label htmlFor="city_to_withdraw" className="form-label mb-0">
                      {t("City")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("City")}
                      autoComplete="off"
                      value={formik.values.city_to_withdraw}
                      onChange={(e) => {
                        formik.setFieldValue("city_to_withdraw", e.target.value)
                      }}
                      name="city_to_withdraw"
                    />
                  </Col>
                </div>
                <div className="mb-3">
                  <Col>
                    <Label htmlFor="account_number" className="form-label mb-0">
                      {t("Account number")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("Account number")}
                      name="account_number"
                      autoComplete={'off'}
                      value={formik.values.account_number}
                      onChange={(e) => {
                        formik.setFieldValue("account_number", e.target.value)
                      }}
                    />
                  </Col>
                </div>
                <div className="mb-3">
                  <Col>
                    <Label htmlFor="iban" className="form-label mb-0">
                      IBAN
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="IBAN"
                      name="iban"
                      autoComplete={'off'}
                      value={formik.values.iban}
                      onChange={(e) => {
                        formik.setFieldValue("iban", e.target.value)
                      }}
                    />
                  </Col>
                </div>
                <div className="mb-3">
                  <Col>
                    <Label htmlFor="swift_code" className="form-label mb-0">
                      {t("Swift code")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("Swift code")}
                      name="swift_code"
                      autoComplete={'off'}
                      value={formik.values.swift_code}
                      onChange={(e) => {
                        formik.setFieldValue("swift_code", e.target.value)
                      }}
                    />
                  </Col>
                </div>
                <div className="mb-3">
                  <Col>
                    <Label htmlFor="customer_id" className="form-label mb-0">
                      {t("Payment reference number")}
                      <sup className="text-danger fs-12">*</sup>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("Payment reference number")}
                      defaultValue={customerId}
                      name="customer_id"
                      readOnly={true}
                    />
                  </Col>
                </div>
                <div className="d-flex align-items-start gap-5 mt-10 mb-3">
                  <Button
                    type="button"
                    className="btn btn-sm btn-light-warning border border-dashed border-warning fs-6 w-50 btn-label"
                    onClick={() => {
                      toggleTab(activeTab - 1)
                    }}
                  >
                    <i className="ki-outline ki-double-left fs-2 pb-1"></i> {t("PREVIOUS")}
                  </Button>
                  <Button
                    type="submit"
                    style={{padding: '9px'}}
                    className="btn btn-sm btn-light-success border border-dashed border-success fs-6 w-50 btn-label"
                    disabled={isButtonDisabled}
                  >
                    {t("SUBMIT")}
                  </Button>
                </div>
              </Row>
            </TabPane>

            <TabPane id="pills-experience" tabId={4}>
              <div className="text-center">
                <div className="mb-4">
                  <lord-icon
                    src="https://cdn.lordicon.com/lupuorrc.json"
                    trigger="loop"
                    colors="primary:#0ab39c,secondary:#405189"
                    style={{width: "120px", height: "120px"}}
                  ></lord-icon>
                </div>
                <h5>{t("Well Done !")}</h5>
                <p className="text-muted">
                  {t("You request for a bank transfer has been submitted, status \"Pending\".")} <br/>
                  {t("If will be necessary, we will contact you.")}
                </p>
              </div>
              <div className="d-flex justify-content-center mt-6">
                <div className="col-6">
                  <Button
                    type="button"
                    className="btn btn-success btn-sm fs-6 fw-bold btn-label w-100 right ms-auto"
                    onClick={() => {
                      toggleTab(activeTab - 3)
                    }}
                  >
                    {/*<i className="ri-arrow-up-line label-icon align-middle fs-16 ms-2"></i>*/}
                    {t("NEW BANK TRANSFER")}
                  </Button>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <input type="hidden" name="fee_value" value={activeTabFee}/>
    </Form>
  )
}

export default BankTransferForm
